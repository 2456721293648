import {Button, ClickAwayListener, Grow, Menu, MenuItem, MenuList, Paper, Popper} from "@material-ui/core";
import React, {useState} from "react";
import {confirmAlert} from "react-confirm-alert";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {useParams} from "react-router-dom";
import {getApplicationMeetings} from "../../../../actions/application";
import {setDialog} from "../../../../actions/dialogs";
import {allMatching} from "../../../../actions/meeting";
import {confirmModalOptions} from "../../../../utils/confirmModalOptions";
import PxButton from "../../../Buttons/PxButton";
import ButtonTypo from "../../../Typhography/ButtonTypo";
import {MatchingType} from "./MeetingList";

interface Props {
  getApplicationMeeting: () => Promise<void>;
  matchDialogMessage: (matchData: any) => string[];
  type: string;
}

const MatchingButton: React.FC<Props> = props => {
  const {getApplicationMeeting, matchDialogMessage, type} = props;
  const param: {id: string} = useParams();
  const dispatch = useDispatch();
  const [t] = useTranslation("lang", {useSuspense: false});
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (matchingType: MatchingType) => {
    if (typeof matchingType == "string") {
      machingConfirmHandler(matchingType);
    }
    setAnchorEl(null);
  };

  const allMatchHandler = async (matchingType: MatchingType) => {
    const mailPostToggleAt = localStorage.getItem("mailPostToggleAt") === null ? "false" : localStorage.getItem("mailPostToggleAt")!.toString();

    const matchingParam: any = {
      subEventId: param.id,
      matchingType: matchingType,
      mailPostToggleAt: mailPostToggleAt,
    };

    const result: any = await dispatch(allMatching(matchingParam));
    dispatch(setDialog(t("meetingList.totalNMatching", {n: result.length}), matchDialogMessage(result)));
    dispatch(getApplicationMeetings({subEventId: param.id, type: type}));
    getApplicationMeeting();
  };

  const machingConfirmHandler = async (matchingType: MatchingType) => {
    confirmAlert(
      confirmModalOptions({
        title: makeMachingConfirmTitle(matchingType),
        message: makeMatchingConfirmMesaage(matchingType),
        click: () => allMatchHandler(matchingType),
      })
    );
  };

  const makeMachingConfirmTitle = (matchingType: MatchingType) => {
    switch (matchingType) {
      case MatchingType.CONNECTION:
        return t("meetingList.connectionConfirmTitle");
      case MatchingType.RECOMMEND:
        return t("meetingList.recommandConfirmTitle");
      case MatchingType.TIGHT:
        return t("meetingList.tightConfirmTitle");
    }
  };

  const makeMatchingConfirmMesaage = (matchingType: MatchingType) => {
    switch (matchingType) {
      case MatchingType.CONNECTION:
        return t("meetingList.connectionConfirmMessage");
      case MatchingType.RECOMMEND:
        return t("meetingList.recommandConfirmMessage");
      case MatchingType.TIGHT:
        return t("meetingList.tightConfirmMessage");
    }
  };

  return (
    <div>
      <div>
        {/* 메인 버튼 */}

        <PxButton backgroundcolor="purple" onClick={handleClick}>
          <ButtonTypo>자동 매칭</ButtonTypo>
        </PxButton>

        {/* 버튼을 클릭하면 드롭다운 메뉴가 나타남 */}
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          getContentAnchorEl={null} // 이 속성을 추가합니다.
          anchorOrigin={{
            vertical: "bottom", // 버튼의 아래쪽을 기준으로
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top", // 메뉴의 위쪽이 anchorEl의 아래쪽에 붙도록
            horizontal: "center",
          }}
        >
          <MenuItem onClick={() => handleClose(MatchingType.RECOMMEND)}>추천 매칭</MenuItem>
          <MenuItem onClick={() => handleClose(MatchingType.CONNECTION)}>연관 매칭</MenuItem>
          <MenuItem onClick={() => handleClose(MatchingType.TIGHT)}>맞춤 매칭</MenuItem>
        </Menu>
      </div>
    </div>
  );
};

export default MatchingButton;
