import {Paper, Typography} from "@material-ui/core";
import MaterialTable, {Column, MTableToolbar} from "material-table";
import React, {useEffect, useMemo, useState} from "react";
import {useDispatch} from "react-redux";
import styled from "styled-components";
import PxButton from "../../../../Buttons/PxButton";
import PxTable from "../../../../Table/PxTable";
import ButtonTypo from "../../../../Typhography/ButtonTypo";
import {StyledDiv, tableIcons, customTheme} from "../../../../Table/PxTable";
import {getRequestAndReturn} from "../../../../../utils/utils";
import {useParams} from "react-router-dom";
import {Bar, BarChart, CartesianGrid, Cell, Legend, Pie, PieChart, Rectangle, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import CustomPieChart from "./CustomPieChart";
import MailStatsTable from "./MailStatsTable";
import {MailReSendUsersInfo, RequestInfo} from "../../../../../types/models/Mail";
import {createMailRequestInfoAction} from "../../../../../actions/mail";
import CustomBarChart from "./CustomBarChart";

type SuccessRowType = {
  name: string;
  email: string;
};

const columnData: Array<Column<MailReSendUsersInfo>> = [
  {
    title: "이름",
    field: "name",
  },
  {
    title: "이메일",
    field: "email",
  },
  {
    title: "이유",
    field: "cause",
  },
];

export type ChartDataType = {
  name: string;
  value: number;
};

export type StatsDataType = {
  totalCount: number;
  deliveries: number;
  opens: number;
  complaints: number;
  bounces: number;
  rejects: number;
  clicks: number;
  fails: number;
};

export enum MailStatus {
  SEND = "SEND",
  FAIL = "FAIL",
  COMPLAINTS = "COMPLAINTS",
  REJECT = "REJECT",
  OPEN = "OPEN",
  BOUNCE = "BOUNCE",
  DELIVERY = "DELIVERY",
  CLICK = "CLICK",
}

const RequestDashboard: React.FC = () => {
  const dispatch = useDispatch();
  const params = useParams<{secondId: string}>();
  const [chartData, setChartData] = useState<Array<ChartDataType>>([]);
  const [statsData, setStatsData] = useState<StatsDataType>({
    totalCount: 0,
    bounces: 0,
    deliveries: 0,
    opens: 0,
    complaints: 0,
    rejects: 0,
    clicks: 0,
    fails: 0,
  });

  useEffect(() => {
    // setData([{name: "abcd", email: "samrong2@kinterch.com", cause: ""}]);
    getRequestStats();
  }, []);

  const getRequestStats = async () => {
    const res: any = await dispatch(getRequestAndReturn(`/api/mails/request/${params.secondId}/stats`, {}));
    dispatch(createMailRequestInfoAction(res as RequestInfo));
    mappingChartData(res as StatsDataType);
    setStatsData(res as StatsDataType);
  };

  const mappingChartData = (res: StatsDataType) => {
    let totalFailValue = 0;
    const changeData = Object.entries(res).reduce<ChartDataType[]>((acc, [key, value]) => {
      if (typeof value !== "number" || key === "totalCount" || key === "id") {
        return acc;
      }

      if (key === "bounces" || key === "fails") {
        totalFailValue += value;
        return acc;
      }

      acc.push({
        name: keyChangeToName(key),
        value: value as number,
      });

      return acc;
    }, []);

    // Add the combined fails and bounces value
    changeData.push({
      name: "전송 실패",
      value: totalFailValue,
    });
    setChartData(changeData);
  };

  const keyChangeToName = (key: string) => {
    switch (key) {
      case "deliveries":
        return "전송";
      case "opens":
        return "열기";
      case "complaints":
        return "신고";
      case "rejects":
        return "거절";
      case "clicks":
        return "클릭";
      case "fails":
        return "전송 실패";
      case "bounces":
        return "반송";
      default:
        return key;
    }
  };

  return (
    <>
      {/* {memoizedChartData && <CustomPieChart title="메일 전송 통계" chartData={memoizedChartData} />} */}
      <CustomBarChart title="메일 전송 통계" chartData={chartData} />
      <MailStatsTable statsData={statsData} />
    </>
  );
};
export default RequestDashboard;

const Container = styled.div``;
