import {Button, Menu, MenuItem} from "@material-ui/core";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {getApplicationMeetings} from "../../../../actions/application";
import {setPxDialogAction} from "../../../../actions/dialogs";
import {allMatchingCancel, autoMatchingCancel} from "../../../../actions/meeting";
import {AppState} from "../../../../store";
import PxButton from "../../../Buttons/PxButton";
import WarningRemove from "../../../Dialogs/WaringRemove";
import ButtonTypo from "../../../Typhography/ButtonTypo";
import {MatchingType} from "./MeetingList";

interface Props {
  getApplicationMeeting: () => Promise<void>;
  type: string;
}

const MatchingCancelButton: React.FC<Props> = props => {
  const {getApplicationMeeting, type} = props;
  const dispatch = useDispatch();
  const [t] = useTranslation("lang", {useSuspense: false});
  const subEvent = useSelector((state: AppState) => state.subEvents.subEvent);
  const param: {id: string} = useParams();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (matchingType: MatchingType) => {
    matchCancelClick(matchingType);
    setAnchorEl(null);
  };

  const getNoticeSentence = (matchingType: MatchingType) => {
    switch (matchingType) {
      case MatchingType.TIGHT:
        return "Tight 매칭을 취소 하시겠습니까?";
      case MatchingType.RECOMMEND:
        return "Recommend 매칭을 취소 하시겠습니까?";
      case MatchingType.CONNECTION:
        return "Connection 매칭을 취소 하시겠습니까?";
    }
  };

  const matchCancelClick = (matchingType: MatchingType) => {
    if (typeof matchingType != "string") return;
    subEvent?.mainEvent &&
      dispatch(
        setPxDialogAction({
          open: true,
          children: (
            <WarningRemove
              // title={t("meetingList.canceltheEntireMatch")}
              title={getNoticeSentence(matchingType)}
              warningMsg={t("meetingList.allMatchingHaveBeenCanceled")}
              text={subEvent.mainEvent.name}
              onClick={() => matchingTypeCancel(matchingType)}
              submitText={t("meetingList.iUnderstoodAndDelete")}
            />
          ),
          footer: <></>,
        })
      );
  };

  const matchingTypeCancel = async (matchingType: MatchingType) => {
    await dispatch(autoMatchingCancel(Number(subEvent?.id), matchingType));
    dispatch(getApplicationMeetings({subEventId: param.id, type: type}));
    getApplicationMeeting();
  };

  // 전체 매칭 취소 (불가시간, 신청, 체결 초기화)
  const allMatchCancelHandler = async () => {
    const mailPostToggleAt = localStorage.getItem("mailPostToggleAt") === null ? "false" : localStorage.getItem("mailPostToggleAt")!.toString();

    const matchingParam: any = {
      subEventId: param.id,
      mailPostToggleAt: mailPostToggleAt,
    };

    const result: any = await dispatch(allMatchingCancel(matchingParam));
    getApplicationMeeting();
    if (result === "success") dispatch(getApplicationMeetings({subEventId: param.id, type: type}));
  };

  return (
    <div style={{marginLeft: "20px"}}>
      <div>
        {/* 메인 버튼 */}
        <PxButton backgroundcolor="purple" onClick={handleClick}>
          <ButtonTypo> 취소 </ButtonTypo>
        </PxButton>

        {/* 버튼을 클릭하면 드롭다운 메뉴가 나타남 */}
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          getContentAnchorEl={null} // 이 속성을 추가합니다.
          anchorOrigin={{
            vertical: "bottom", // 버튼의 아래쪽을 기준으로
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top", // 메뉴의 위쪽이 anchorEl의 아래쪽에 붙도록
            horizontal: "center",
          }}
        >
          <MenuItem onClick={() => handleClose(MatchingType.RECOMMEND)}>추천매칭 취소</MenuItem>
          <MenuItem onClick={() => handleClose(MatchingType.CONNECTION)}>연관매칭 취소</MenuItem>
          <MenuItem onClick={() => handleClose(MatchingType.TIGHT)}>맞춤매칭 취소</MenuItem>
        </Menu>
      </div>
    </div>
  );
};

export default MatchingCancelButton;
