import {Button, Input} from "antd";
import React, {Dispatch, SetStateAction, useEffect} from "react";
import PxButton from "../../../../components/Buttons/PxButton";
import ButtonTypo from "../../../../components/Typhography/ButtonTypo";

interface Props {
  title: string;
  newItemTitle: string;
  setNewItemTitle: Dispatch<SetStateAction<string>>;
  handleAddItemConfirm: () => void;
  handleAddItemCancel: () => void;
}

const EditFiled: React.FC<Props> = ({title, newItemTitle, setNewItemTitle, handleAddItemConfirm, handleAddItemCancel}) => {
  useEffect(() => {
    setNewItemTitle(title);
  }, []);

  return (
    <div>
      <Input
        placeholder="Enter title"
        value={newItemTitle}
        onClick={e => e.stopPropagation()}
        onChange={e => {
          e.stopPropagation();
          setNewItemTitle(e.target.value);
        }}
        style={{width: "50%", marginRight: "8px"}}
      />
      <Button
        // type="primary"
        style={{backgroundColor: "#d9d9d9"}}
        onClick={e => {
          e.stopPropagation();
          handleAddItemConfirm();
        }}
      >
        저장
      </Button>
      <Button
        style={{marginLeft: "2px"}}
        onClick={e => {
          e.stopPropagation();
          handleAddItemCancel();
        }}
      >
        취소
      </Button>
    </div>
  );
};

export default EditFiled;
