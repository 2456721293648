import React, {useCallback, useEffect} from "react";
import PxGridContainer from "../../../components/Grid/PxGridContainer";
import PxGridItem from "../../../components/Grid/PxGridItem";
import PxInputBase from "../../../components/Inputs/PxInputBase";
import PxOutlinedTxtField from "../../../components/Inputs/PxOutlinedTxtField";

// types
import {AutonoForm} from "../../../types/models/AutonoForm";

// material comp
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import PxRadioButton from "../../../components/Radios/PxRadioButton";
import PxCheckBox from "../../../components/Checkboxes/PxCheckBox";
import PxButton from "../../../components/Buttons/PxButton";
import {categoryAndCheckboxSelectTextToLinkAndText} from "../../../utils/utils";
import Rating from "@material-ui/lab/Rating";
import Box from "@material-ui/core/Box";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CategoryView, {CategoryViewType} from "../autonomous/category/CategoryView";

const FormPreviewItem: React.FC<AutonoForm> = props => {
  const {
    // id,
    type,
    title,
    explain,
    explainCheck,
    // secretCheck,
    // requireCheck,
    // index,
    selectContent,
    keywordOptions,
  } = props;

  const setPreviewSelectAndCheckBoxLabel = useCallback((text: string) => {
    const linkTextStyle: React.CSSProperties = {
      textDecoration: "underline",
      fontWeight: "bold",
      color: "blue",
      cursor: "pointer",
    };

    const result = categoryAndCheckboxSelectTextToLinkAndText(text);
    if (typeof result === "string") {
      return result;
    } else {
      return (
        <>
          {result.priorText}
          <span
            style={linkTextStyle}
            onClick={event => {
              window.open(result.link, "_blank");
            }}
          >
            {result.linkText}
          </span>
          {result.endText}
        </>
      );
    }
  }, []);

  return (
    <PxGridContainer className="py-3 pl-3" direction="column">
      {type !== "product" && (
        <PxGridItem>
          <PxInputBase
            name="title"
            value={title === "" ? "" : title}
            // ${freeFormQuestionText(title!, type!)}
            fullWidth
            fontsize={15}
            readOnly={true}
            fontweight="bold"
            autoComplete="off"
          />
        </PxGridItem>
      )}
      {explainCheck === "Y" && (
        <PxGridItem>
          <PxInputBase name="explain" value={explain} multiline fullWidth placeholder="" fontsize={14} fontweight="bold" readOnly />
        </PxGridItem>
      )}
      {(type === "long" || type === "short" || type === "url") && (
        <PxGridItem>
          <PxOutlinedTxtField inputpadding={type === "long" ? 0 : 10} multiline={type === "long" ? true : false} rows={2} fullWidth placeholder="" fontsize={14} />
        </PxGridItem>
      )}
      {type === "keyword" && (
        <>
          <CategoryView type={keywordOptions && keywordOptions.length > 1 ? CategoryViewType.SELLER : CategoryViewType.BUYER} initData={keywordOptions} />
        </>

        // <PxGridItem>
        //   <Autocomplete
        //     id="keywordOptions"
        //     options={keywordOptions ?? []}
        //     multiple
        //     renderInput={params => <PxOutlinedTxtField {...params} inputProps={{...params.inputProps, style: {padding: 0}}} fullWidth fontsize={14} />}
        //   />
        // </PxGridItem>
      )}
      {type === "many" && (
        <PxGridItem>
          <FormControl>
            <RadioGroup>
              {selectContent!.formIds.map((formId, index) => (
                <PxCheckBox label={selectContent!.formData[formId].explain! === "" ? "" : setPreviewSelectAndCheckBoxLabel(selectContent!.formData[formId].explain!)} />
              ))}
            </RadioGroup>
          </FormControl>
        </PxGridItem>
      )}
      {type === "file" && (
        <PxGridItem>
          <PxButton backgroundcolor="grey">추가하기</PxButton>
        </PxGridItem>
      )}
      {type === "star" && (
        <PxGridItem>
          <Box borderColor="grey.500" borderRadius={5} border={1} style={{textAlign: "center"}}>
            <Rating size="large" className="mt-1" />
          </Box>
        </PxGridItem>
      )}
      {type === "product" && (
        <PxGridItem style={{border: "1px solid rgba(0, 0, 0, 0.23)", borderRadius: "7px", padding: "10px"}}>
          <PxInputBase name="productName" value={"제품명"} fullWidth fontsize={15} readOnly={true} fontweight="bold" autoComplete="off" />
          <PxInputBase name="explain" value={"제품명을 입력해주세요."} multiline fullWidth placeholder="" fontsize={14} readOnly />
          <PxOutlinedTxtField inputpadding={10} multiline={false} rows={2} fullWidth placeholder="" fontsize={14} />
          <PxInputBase name="productExplain" value={"제품 설명"} fullWidth fontsize={15} readOnly={true} fontweight="bold" autoComplete="off" />
          <PxInputBase name="explain" value={"제품 설명을 입력해주세요."} multiline fullWidth placeholder="" fontsize={14} readOnly />
          <PxOutlinedTxtField inputpadding={0} multiline={true} rows={2} fullWidth placeholder="" fontsize={14} />
          <PxInputBase name="productImage" value={"제품 이미지"} fullWidth fontsize={15} readOnly={true} fontweight="bold" autoComplete="off" />
          <PxInputBase name="explain" value={"제품 이미지(사이즈 600*600, 용량 10mb 이하)를 등록해주세요."} multiline fullWidth placeholder="" fontsize={14} readOnly />
          <PxButton backgroundcolor="grey">추가하기</PxButton>
          <PxInputBase name="productLink" value={"제품 링크"} fullWidth fontsize={15} readOnly={true} fontweight="bold" autoComplete="off" />
          <PxInputBase name="explain" value={"제품 링크를 입력해주세요."} multiline fullWidth placeholder="" fontsize={14} readOnly />
          <PxOutlinedTxtField inputpadding={10} multiline={false} rows={2} fullWidth placeholder="" fontsize={14} />
        </PxGridItem>
      )}
    </PxGridContainer>
  );
};

export default FormPreviewItem;

// const setRadioGroupData = () => {
//   const radioData: RadioData[] = selectContent!.formIds.map(
//     (formId, index) => {
//       return {
//         value:
//           selectContent!.formData[formId].explain! === ""
//             ? "선택할 각 항목을 입력해주세요."
//             : selectContent!.formData[formId].explain!,
//         label:
//           selectContent!.formData[formId].explain! === ""
//             ? "선택할 각 항목을 입력해주세요."
//             : selectContent!.formData[formId].explain!,
//       };
//     }
//   );
//   return <PxRadioGroup value={""} data={radioData} radiogroupname={title!} />;
// };
