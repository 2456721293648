import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {removeFormAction, updateTemplate} from "../../../actions/forms";
import {getSubEvent, modifyCounselFormOnlyEng} from "../../../actions/subEvents";
import uuid from "uuid";
import {useTranslation} from "react-i18next";
import styled from "styled-components";

// custom comp
import MakeFormComponent from "./MakeFormComponent";
import useFormContext from "../../../components/Provider/FormProvider/useFormContext";
import {AppState} from "../../../store";
import {setAlert} from "../../../actions/alerts";
import SubHeadingTypo from "../../../components/Typhography/SubHeadingTypo";
import Box from "@material-ui/core/Box";
import PxGridContainer from "../../../components/Grid/PxGridContainer";
import PxGridItem from "../../../components/Grid/PxGridItem";
import withStyles from "@material-ui/styles/withStyles";
import Switch from "@material-ui/core/Switch";
import {removeLoadingAction, setLoadingAction} from "../../../actions/loading";

import ListAltIcon from "@material-ui/icons/ListAltOutlined";
// import {IconButton} from "@material-ui/core";
import {defaultSgroup, defaultBgroup, exportSgroup, exportBgroup, irBgroup, irSgroup, jobBgroup, jobSgroup, tourBgroup, tourSgroup} from "../setting/template/templateData";
import FormPreviewItem from "../preview/FormPreviewItem";
import PxButton from "../../../components/Buttons/PxButton";
import {Button, Tab, Tabs} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import {Form, FormElement} from "../../../types/models/Form";
import {KeywordType} from "../../../types/models/AutonoForm";

const PurpleSwitch = withStyles({
  switchBase: {
    "&$checked": {
      color: "rgb(81, 45, 168)",
    },
    "&$checked + $track": {
      backgroundColor: "rgb(81, 45, 168)",
    },
  },
  checked: {},
  track: {},
})(Switch);

interface FormDataType {
  [key: string]: {
    id: string;
    index: number;
    type: "file" | "single" | "many" | "short" | "long" | "url" | "keyword" | "category" | "star" | undefined;
    selectable: boolean;
    optionable: boolean;
    title: string;
    explain: string;
    secretCheck: string;
    explainCheck: "Y" | "N" | undefined;
    requireCheck: string;
    explainable: boolean;
    selectContent: {
      formData: SelectedContent;
      formIds: string[];
    };
    content: string;
    keywordOptions: KeywordType[];
  };
}

interface TemplateForm {
  formIds: string[];
  formData: FormDataType;
}

interface SelectedContent {
  [key: string]: {
    id: string;
    explain: string;
    index: number;
  };
}

type TemplateType = "default" | "export" | "ir" | "job" | "tour";

const MeetingFormSetting: React.FC = () => {
  const [t] = useTranslation("lang", {useSuspense: false});
  const history = useHistory();
  const dispatch = useDispatch();
  const {setInitialLoad} = useFormContext(); // 처음 자율양식 페이지에 들어왔을때, 자율양식을 불러오는 api 호출 yes or no 판단
  const params = useParams<{id: string}>(); // for getting 서브 이벤트 id
  const subEvent = useSelector((state: AppState) => state.subEvents.subEvent);
  const event = useSelector((state: AppState) => state.events.event);
  const [tempPreviewState, setTempPreviewState] = useState<Boolean>(false);

  const [sGroupFormData, setSGroupFormData] = useState<TemplateForm>();
  const [bGroupFormData, setBGroupFormData] = useState<TemplateForm>();
  const [tabValue, setTabValue] = useState<string>("sgroup");
  const [templateType, setTemplateType] = useState<TemplateType>("default");

  useEffect(() => {
    dispatch(getSubEvent(parseInt(params.id)));
    setInitialLoad(true);
    dispatch(removeFormAction());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log(`sgroupFormData : `, sGroupFormData);
  }, [sGroupFormData]);

  useEffect(() => {
    return () => {
      if (history.action === "POP") {
        history.push(`/event/detail/${event!.id}`);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  const templateOnClick = (type: TemplateType) => {
    setTempPreviewState(true);
    setTemplateType(type);
    switch (type) {
      case "default":
        makeFormData(defaultSgroup, "sgroup");
        makeFormData(defaultBgroup, "bgroup");
        break;
      case "export":
        makeFormData(exportSgroup, "sgroup");
        makeFormData(exportBgroup, "bgroup");
        break;
      case "ir":
        makeFormData(irSgroup, "sgroup");
        makeFormData(irBgroup, "bgroup");
        break;
      case "job":
        makeFormData(jobSgroup, "sgroup");
        makeFormData(jobBgroup, "bgroup");
        break;
      case "tour":
        makeFormData(tourSgroup, "sgroup");
        makeFormData(tourBgroup, "bgroup");
        break;
    }
  };

  const makeFormData = (templateData: FormElement[], group: "sgroup" | "bgroup") => {
    const keyList: string[] = [];

    const data = templateData.map((item, idx) => {
      const formId = `form-${uuid.v4()}`;
      keyList.push(formId);

      let formIds: string[] = [];
      let formData: any;

      if (item.content) {
        const answerData = item.content?.map((v, idx) => {
          const answerId = `form-${uuid.v4()}`;
          idx === 0 ? (formIds = [answerId]) : formIds.push(answerId);
          return {
            [answerId]: {
              id: answerId,
              explain: v,
              index: idx,
            },
          };
        });
        formData = Object.assign({}, ...answerData);
      }

      return {
        [formId]: {
          id: formId,
          index: 0,
          type: item.type,
          selectable: false,
          optionable: true,
          title: item.title,
          explain: item.explain,
          secretCheck: "N",
          explainCheck: item.explainable ? "Y" : "N",
          requireCheck: item.required ? "Y" : "N",
          explainable: item.explainable,
          selectContent: {
            formData,
            formIds,
          },
          content: "",
          keywordOptions: item?.keywordOption == undefined ? [] : item?.keywordOption,
        },
      };
    });
    group == "sgroup" ? setSGroupFormData({formIds: keyList, formData: Object.assign({}, ...data)}) : setBGroupFormData({formIds: keyList, formData: Object.assign({}, ...data)});
  };

  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setTabValue(newValue);
  };

  const modalCancelBtn = () => {
    setTempPreviewState(false);
  };

  const templateSelectBtn = () => {
    setTempPreviewState(false);
    dispatch(updateTemplate(templateType));
  };

  const TemplateBtn = (type: TemplateType) => {
    const defaultStyle = {padding: "10px", fontSize: "4.5rem", width: "20%"};

    const selectStyle = {padding: "10px", fontSize: "4.5rem", width: "20%", border: "4px solid rgb(81,45,168)"};

    const templateName = (type: TemplateType) => {
      switch (type) {
        case "default":
          return "기본";
        case "export":
          return "수출/구매";
        case "ir":
          return "투자/컨설팅";
        case "job":
          return "구인/구직";
        case "tour":
          return "관광/여행";
      }
    };
    // backgroundcolor={templateType === type ? "purple" : "#f1f2f5"}
    return (
      <PxButton onClick={() => templateOnClick(type)} style={templateType === type ? selectStyle : defaultStyle}>
        <TemplateItemWrap>
          <ListAltIcon fontSize="inherit" />
          <h1 style={{fontSize: "1rem"}}>{templateName(type)}</h1>
        </TemplateItemWrap>
      </PxButton>
    );
  };

  return (
    <>
      {subEvent && subEvent.id && (
        <PxGridContainer alignItems="center" justify="space-between">
          <PxGridItem>
            <SubHeadingTypo fontweight="bold" gutterBottom>
              참가자 참가 신청시 영어만 입력가능
            </SubHeadingTypo>
          </PxGridItem>

          <PxGridItem>
            <PurpleSwitch
              checked={subEvent?.counselFormOnlyEng == "Y"}
              onClick={async e => {
                e.stopPropagation();
                dispatch(setLoadingAction());
                await dispatch(modifyCounselFormOnlyEng({id: subEvent.id!}));
                dispatch(removeLoadingAction());
              }}
              name="zoomSwitch"
            />
          </PxGridItem>
        </PxGridContainer>
      )}
      <Box paddingY={2} />
      <SubHeadingTypo fontweight="bold" gutterBottom>
        원하시는 양식을 선택해주세요.
      </SubHeadingTypo>
      <div style={{display: "flex", justifyContent: "space-around"}}>
        {TemplateBtn("default")}
        {TemplateBtn("export")}
        {TemplateBtn("ir")}
        {TemplateBtn("job")}
        {TemplateBtn("tour")}
      </div>

      {/* <SubHeadingTypo fontweight="bold" gutterBottom>
        {subEvent?.sgroupName}
      </SubHeadingTypo> */}
      {/* 셀러 참가신청 */}
      <MakeFormComponent
        title={`${subEvent?.sgroupName} ${t("form.registration")}`}
        onClick={() => {
          history.push(`/form/subEvent/${params.id}/match/sellerPartiForm`);
        }}
        required={true}
        done={subEvent?.sellerPartiForm === 0 ? false : true}
      />
      <MakeFormComponent
        title={`${subEvent?.bgroupName} ${t("form.registration")}`} // 바이어
        onClick={() => {
          if (subEvent?.sellerPartiForm === 0) {
            dispatch(
              setAlert({
                id: uuid.v4(),
                msg: `${subEvent.sgroupName} 참가신청양식을 먼저 만들어주세요.`, // 셀러
                alertType: "warning",
              })
            );
          } else {
            history.push(`/form/subEvent/${params.id}/match/buyerPartiForm`);
          }
        }}
        required={true}
        done={subEvent?.buyerPartiForm === 0 ? false : true}
      />
      {/* 셀러 상담일지 */}
      <MakeFormComponent
        title={`${t("form.meetingNote")}`} // 셀러
        onClick={() => {
          history.push(`/form/subEvent/${params.id}/match/counselForm`);
        }}
        done={subEvent?.sellerCounselForm === 0 ? false : true}
      />
      {/* 셀러 사후설문 */}
      <MakeFormComponent
        title={`${t("form.postSurvey")}`} // 셀러
        onClick={() => {
          history.push(`/form/subEvent/${params.id}/match/surveyForm`);
        }}
        done={subEvent?.sellerSurveyForm === 0 ? false : true}
      />

      {tempPreviewState && sGroupFormData && bGroupFormData && (
        <ModalBackground>
          <TemplateModal>
            <Tabs value={tabValue} onChange={handleChange} indicatorColor="primary">
              <Tab value={"sgroup"} label={subEvent?.sgroupName} fullWidth />
              <Tab value={"bgroup"} label={subEvent?.bgroupName} fullWidth />
            </Tabs>

            <Button onClick={modalCancelBtn} style={{width: "24px", height: "48px", position: "absolute", right: 0}}>
              <CloseIcon />
            </Button>

            <TemplateContentWrap>
              {/* <TabPanel value={tabValue}> */}
              {tabValue == "sgroup"
                ? sGroupFormData.formIds?.map((item, idx) => {
                    return (
                      <FormPreviewItem
                        key={idx}
                        title={sGroupFormData.formData[item].title}
                        type={sGroupFormData.formData[item].type}
                        id={sGroupFormData.formData[item].id}
                        explainCheck={sGroupFormData.formData[item].explainCheck}
                        explain={sGroupFormData.formData[item].explain}
                        selectContent={sGroupFormData.formData[item].selectContent}
                        keywordOptions={sGroupFormData.formData[item].keywordOptions}
                      />
                    );
                  })
                : bGroupFormData.formIds?.map((item, idx) => {
                    return (
                      <FormPreviewItem
                        key={idx}
                        title={bGroupFormData.formData[item].title}
                        type={bGroupFormData.formData[item].type}
                        id={bGroupFormData.formData[item].id}
                        explainCheck={bGroupFormData.formData[item].explainCheck}
                        explain={bGroupFormData.formData[item].explain}
                        selectContent={bGroupFormData.formData[item].selectContent}
                        keywordOptions={bGroupFormData.formData[item].keywordOptions}
                      />
                    );
                  })}
              {/* </TabPanel> */}
              <ModalBottomWrap>
                <PxButton backgroundcolor="purple" txtColor="white" margin="5px" onClick={modalCancelBtn}>
                  취소
                </PxButton>
                <PxButton backgroundcolor="purple" txtColor="white" margin="5px" onClick={templateSelectBtn}>
                  선택
                </PxButton>
              </ModalBottomWrap>
            </TemplateContentWrap>
          </TemplateModal>
        </ModalBackground>
      )}

      {/* <Box paddingY={5} />
      <SubHeadingTypo fontweight="bold" gutterBottom>
        {subEvent?.bgroupName}
      </SubHeadingTypo> */}
      {/* 바이어 참가신청 */}
      {/* <MakeFormComponent
        title={`${subEvent?.bgroupName} ${t("form.registration")}`} // 바이어
        onClick={() => {
          if (subEvent?.sellerPartiForm === 0) {
            dispatch(
              setAlert({
                id: uuid.v4(),
                msg: `${subEvent.sgroupName} 참가신청양식을 먼저 만들어주세요.`, // 셀러
                alertType: "warning",
              })
            );
          } else {
            history.push(`/form/subEvent/${params.id}/match/buyerPartiForm`);
          }
        }}
        required={true}
        done={subEvent?.buyerPartiForm === 0 ? false : true}
      /> */}
      {/* 바이어 상담일지 */}
      {/* <MakeFormComponent
        title={`${subEvent?.bgroupName} ${t("form.meetingNote")}`} // 바이어
        onClick={() => {
          history.push(`/form/subEvent/${params.id}/match/buyerCounselForm`);
        }}
        done={subEvent?.buyerCounselForm === 0 ? false : true}
      /> */}
      {/* 바이어 사후설문 */}
      {/* <MakeFormComponent
        title={`${subEvent?.bgroupName} ${t("form.postSurvey")}`}
        onClick={() => {
          history.push(`/form/subEvent/${params.id}/match/buyerSurveyForm`);
        }}
        done={subEvent?.buyerSurveyForm === 0 ? false : true}
      /> */}
    </>
  );
};

export default MeetingFormSetting;

const TemplateModal = styled.div`
  display: flex;
  width: 37.5rem;
  height: 40rem;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  border-radius: 10px;
`;

const ModalBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
`;

const ModalBottomWrap = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
`;

const TemplateItemWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const TemplateContentWrap = styled.div`
  overflow: auto;
`;
