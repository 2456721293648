/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react";
import uuid from "uuid";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";

// types
import {FormData, KeywordType} from "../../../types/models/AutonoForm";
import {Form} from "../../../types/models/Form";

// actions
import {AppState} from "../../../store";
import {setForms, createForm} from "../../../actions/forms";

// custom comp
import PxAutonoForm from "./PxAutonomousForm";

// material comp
import AutonomousForm from "./AutonomousForm";
import PxButton from "../../../components/Buttons/PxButton";
import ButtonTypo from "../../../components/Typhography/ButtonTypo";
import PxGridContainer from "../../../components/Grid/PxGridContainer";

// material icons
import AddIcon from "@material-ui/icons/Add";
import {useTranslation} from "react-i18next";
import {removeLoadingAction, setLoadingAction} from "../../../actions/loading";
import _ from "lodash";
import {exportBgroup, irBgroup, jobBgroup, tourBgroup} from "../setting/template/templateData";
import {SubEventCategory} from "../../../types/models/SubEvent";

interface AutoFormProps {
  initialFormData: Form | undefined; // default form data
  setFormData?: React.Dispatch<React.SetStateAction<FormData | undefined>>;
  subEventCategory?: SubEventCategory;
}

type Props = AutoFormProps;

export interface FormDataType extends FormData {
  keywords: KeywordType[];
}

const AutoForm: React.FC<Props> = ({initialFormData, setFormData, subEventCategory}) => {
  const [t] = useTranslation("lang", {useSuspense: false});
  const history = useHistory();
  const dispatch = useDispatch();
  const {id, formData, content, templateType} = useSelector((state: AppState) => state.forms);
  const {subEvent} = useSelector((state: AppState) => state.subEvents);
  const keywords = useSelector((state: AppState) => state.forms.keywords);
  // const [defaultForm, setDefaultForm] = useState<FormData>();
  const [initData, setInitialData] = React.useState<FormData>({
    formData: {},
    formIds: [],
  });
  const [defaultInit, setDefaultInit] = useState<FormData>({
    formData: {},
    formIds: [],
  });
  const {forms} = useSelector((state: AppState) => ({forms: state.forms}));

  // 바이어 참가양식 등록 시 셀러의 카테고리 및 키워드를 가져오는 api call
  const getSellerPartiForm = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Accept-Language": "ko",
      },
      params: {
        id: subEvent?.sellerPartiForm,
      },
      data: {},
    };
    dispatch(setLoadingAction());
    try {
      const result = await axios.get("/api/forms/detail", config);

      const sellerFormData: FormData = JSON.parse(result.data.content);

      const sellerCategoryForm = sellerFormData.formData[sellerFormData.formIds[1]];
      const sellerKeywordForm = sellerFormData.formData[sellerFormData.formIds[2]];

      setInitialData(prevState => {
        const formData = prevState.formData;
        formData[prevState.formIds[1]].requireCheck = "N";
        formData[prevState.formIds[1]].explainCheck = sellerCategoryForm.explainCheck;
        formData[prevState.formIds[1]].explain = sellerCategoryForm.explain;
        formData[prevState.formIds[1]].selectContent = sellerCategoryForm.selectContent;

        // formData[prevState.formIds[2]].keywordOptions = (sellerKeywordForm.keywordOptions as string[]).concat(formData[prevState.formIds[2]].keywordOptions as string[]);
        // if (templateType !== "default") {
        //   formData[prevState.formIds[2]].keywordOptions = (sellerKeywordForm.keywordOptions as string[]).concat(formData[prevState.formIds[2]].keywordOptions as string[]);
        // } else {
        //   formData[prevState.formIds[2]].keywordOptions = sellerKeywordForm.keywordOptions;
        // }

        return {...prevState, formData};
      });
    } catch (err) {
      history.goBack();
    }
    dispatch(removeLoadingAction());
  };

  // (상담회)바이어의 카테고리 및 키워드를 셀러의 카테고리 및 키워드 양식과 똑같이 맞춰줌
  // useEffect(() => {
  //   if (subEvent !== undefined && subEvent.sellerPartiForm !== 0 && (initialFormData as Form).type === "BYP" && id === undefined) {
  //     getSellerPartiForm();
  //   }
  // }, [subEvent]);

  useEffect(() => {
    if (formData !== undefined) {
      if (formData!.formIds.length === 0) {
        const data: FormData = (initialFormData! as Form).formData!;
        setInitialData(data);
        setDefaultInit(data);
      } else {
        setInitialData(formData);
      }
    }
  }, [formData, content]);

  useEffect(() => {
    if (subEventCategory !== "registration") return;
    if (setFormData == undefined) return;
    if (initData == undefined) return;
    setFormData({formData: initData.formData, formIds: initData.formIds});
  }, [initData, subEventCategory, setFormData]);

  // 양식추가하기 버튼 onClick function
  const formAddButtonOnClick = () => {
    // 기본 자율양식 form 데이터
    const newFormId = `form-${uuid.v4()}`;
    const newForm = {
      [newFormId]: {
        id: newFormId,
        index: 0,
        type: "single",
        title: "",
        explain: "",
        secretCheck: "N",
        explainCheck: "N",
        requireCheck: "N",
        explainable: true,
        secretable: true,
        requirable: true,
        selectContent: {
          formData: {
            [newFormId]: {
              id: newFormId,
              explain: "",
              index: 0,
            },
          },
          formIds: [newFormId],
        },
      },
    };

    setInitialData(prevState => {
      const formData = Object.assign(prevState.formData, newForm);
      const formIds = prevState.formIds;
      formIds.push(newFormId);

      return {...prevState, formData, formIds};
    });
  };

  // 양식 복사 onClick fucntion
  const formCopyButtonOnClick = (formId: string, index: number) => {
    const newFormId = `form-${uuid.v4()}`;
    // 복사할 폼과 같은 폼 생성
    const newForm = {
      [newFormId]: {
        ...initData.formData[formId],
        id: newFormId,
        selectContent: {
          formData: {},
          formIds: [] as string[],
        },
      },
    };

    // 단일 및 복수 선택지 내용을 복사하기 위한 변수
    const selectContent: FormData = {
      formData: {},
      formIds: [],
    };

    // 전체 form에 selectForm의 key값과 id 값만 변경해서 넣어줌
    for (let [, value] of Object.entries(initData.formData[formId].selectContent!.formData)) {
      const selectFormId = `form-${uuid.v4()}`;
      Object.assign(selectContent.formData, {
        [selectFormId]: {
          id: selectFormId,
          explain: value.explain,
          index: value.index,
        },
      });
    }

    // select Form의 index값에 따라 정렬 후 formIds 값에 넣어줌
    const selectFormOrderByIndex = Object.values(selectContent.formData).sort((a, b) => a.index! - b.index!);
    const formIds = selectFormOrderByIndex.map(value => {
      return value.id;
    });
    selectContent.formIds = formIds;

    // select content object값 갱신
    Object.assign(newForm[newFormId].selectContent, selectContent);

    // 생성된 폼을 formData에 추가하고 formIds 배열에 기존폼 index 옆에 추가
    setInitialData(prevState => {
      const formData = Object.assign(initData.formData, newForm);
      const formIds = prevState.formIds;
      formIds.splice(index + 1, 0, newFormId);
      return {...prevState, formData, formIds};
    });
  };

  // 양식 삭제 onClick function
  const formDeleteButtonOnClick = (selectId: string) => {
    // const newData = initData.formData.filter((value) => value.id !== selectId);
    delete initData.formData[selectId]; // delete object property
    setInitialData(prevState => {
      // delete formId in formIds array
      const formIds = prevState.formIds.filter(value => value !== selectId);
      return {...prevState, formIds};
    });
  };

  // 복수/단수 선택 양식 추가 onClick function
  const selectContentFormAdd = (formId: string) => {
    const newFormId = `form-${uuid.v4()}`;
    const newSelectForm = {
      [newFormId]: {
        id: newFormId,
        explain: "",
        index: initData.formData[formId].selectContent?.formIds.length,
      },
    };

    setInitialData(prevState => {
      const formData = prevState.formData;
      Object.assign(formData[formId].selectContent?.formData, newSelectForm);
      formData[formId].selectContent?.formIds.push(newFormId);
      return {...prevState, formData};
    });
  };

  // 복수/단수 선택 폼 삭제 메소드
  const selectContentFormDelete = (formId: string, selectFormId: string) => {
    setInitialData(prevState => {
      const formData = prevState.formData;
      delete formData[formId].selectContent?.formData[selectFormId];
      const formIds = formData[formId].selectContent?.formIds.filter(value => value !== selectFormId);
      formData[formId].selectContent!.formIds = formIds!;
      return {...prevState, formData};
    });
  };

  // 미리보기 버튼 onclick function, redux에 forms data 저장 후 미리보기 화면이동
  const previewOnClick = () => {
    const form: Form = {
      formData: initData,
    };

    dispatch(setForms(form));
    history.push("/form/parti/preview");
  };

  const keywordRegist = (form: Form) => {
    // for (const [key, value] of Object.entries(form.formData?.formData as object)) {
    //   if (value["keywordOptions"] !== undefined) {
    //     axios.post("/api/keyword/create", {content: value["keywordOptions"], subEventId: subEvent?.id});
    //   }
    // }
  };

  // 완료 버튼 or 다음 버튼 onClick function
  const onSubmit = async () => {
    switch ((initialFormData as Form).type) {
      case "SP": {
        const form: Form = {
          ...initialFormData,
          formData: initData,
          keywords: keywords,
        };
        dispatch(setForms(form));
        keywordRegist(form);
        history.push(`/form/subEvent/${(initialFormData as Form).subEventId}/match/addSellerPartiForm`);
        break;
      }
      case "BYP": {
        const form: Form = {
          ...initialFormData,
          formData: initData,
        };
        dispatch(setForms(form));
        keywordRegist(form);
        history.push(`/form/subEvent/${(initialFormData as Form).subEventId}/match/addBuyerPartiForm`);
        break;
      }
      default: {
        console.log(`여긴가`);
        const form: Form = {
          id: forms.id,
          type: (initialFormData as Form).type,
          subEventId: (initialFormData as Form).subEventId,
          content: JSON.stringify(initData),
        };

        const result: any = await dispatch(createForm(form));
        if (result) {
          history.goBack();
        }
      }
    }
  };

  return (
    <>
      <AutonomousForm
        colunmId="columId-1"
        initialData={initData}
        setInitialData={setInitialData}
        draggableComp={
          <PxAutonoForm
            id=""
            index={0}
            elevation={0}
            setSelected={() => {}}
            draghandle={undefined}
            formCopy={formCopyButtonOnClick}
            formDelete={formDeleteButtonOnClick}
            initialData={initData}
            setInitialData={setInitialData}
            selectContentFormAdd={selectContentFormAdd}
            selectContentFormDelete={selectContentFormDelete}
          />
        }
      />
      {/* {subEventCategory != "registration" && ( */}
      <PxButton fullWidth backgroundcolor="grey" startIcon={<AddIcon />} className="mt-3" onClick={formAddButtonOnClick}>
        <ButtonTypo>{t("registeringForm.addForm")}</ButtonTypo>
      </PxButton>
      {subEventCategory != "registration" && (
        <>
          <PxGridContainer justify="space-between" style={{marginTop: 100}}>
            <PxButton padding="10px 60px 10px 60px" onClick={() => history.goBack()}>
              <ButtonTypo>{t("common.cancel")}</ButtonTypo>
            </PxButton>
            {/* 완성된 양식 미리보기 버튼 */}
            <PxButton backgroundcolor="grey" onClick={previewOnClick} padding="10px 50px 10px 50px">
              <ButtonTypo>{t("common.preview")}</ButtonTypo>
            </PxButton>
            {/* 자율양식 완료 or 다음 버튼 (어떤 양식 만들기냐에 따라 다음 페이지가 있을 수 있고 없을 수 있음)*/}
            <PxButton backgroundcolor="purple" padding="10px 60px 10px 60px" onClick={onSubmit}>
              <ButtonTypo>
                {/* 셀러참가양식, 바이어 참가양식 만들기 페이지에서만 추가양식 페이지로 이동 하기 때문에 구분*/}
                {(initialFormData as Form).type === "SP" || (initialFormData as Form).type === "BYP" ? t("common.next") : t("common.complete")}
                {/* {history.location.pathname.includes("noMatch") ? "완료" : "다음"} */}
              </ButtonTypo>
            </PxButton>
          </PxGridContainer>
        </>
      )}
    </>
  );
};

export default AutoForm;
